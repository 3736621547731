const {
    BASE_ZHL_URLS,
    TEST_FB_APP_ID,
    OPTIMIZE_CONTAINER_ID,
    TEST_GTM_ID,
    TEST_GA_PROPERTY_ID,
    SENTRY_DSN,
    AUTOCOMPLETE_ID,
    ZM_PARTNER_ID,
    SERVICES,
    APPLE_TOUCH_ICON,
    GOOGLE_ANALYTICS_LINK,
    GOOGLE_ANALYTICS_LINK_DEBUG,
    TEST_SAMPLE_RATE,
    TEST_REPLAY_SAMPLE_RATE,
    TEST_AUTOCOMPLETE,
    TEST_ZANALYTICS_OPTIONS,
    BASE_DATADOG_LOP_CONFIG,
    TEST_ZANALYTICS_LOP_OPTIONS,
} = require('./constants');
const { getMaxURL } = require('./local-helpers');

// Needs NEXT_PUBLIC_ prefix in order to be available SSR and in-browser. See: https://nextjs.org/docs/basic-features/environment-variables
const isDebug = process.env.NEXT_PUBLIC_DEBUG;

module.exports = {
    currentDomain: 'https://localhost:8080',
    appleTouchIcon: APPLE_TOUCH_ICON,
    datadog: { replaySampleRate: TEST_REPLAY_SAMPLE_RATE, sampleRate: TEST_SAMPLE_RATE },
    datadogLop: {
        ...BASE_DATADOG_LOP_CONFIG,
        defaultPrivacyLevel: 'mask',
        enableExperimentalFeatures: ['feature_flags'],
        sessionSampleRate: 50,
        sessionReplaySampleRate: 50,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
    },
    facebookAppId: TEST_FB_APP_ID,
    gaLink: isDebug ? GOOGLE_ANALYTICS_LINK_DEBUG : GOOGLE_ANALYTICS_LINK,
    gtmID: TEST_GTM_ID,
    gaPropertyId: TEST_GA_PROPERTY_ID,
    optimizeContainerID: OPTIMIZE_CONTAINER_ID,
    optimizelyProjectID: '20337381224',
    sentryDSN: SENTRY_DSN,
    zAnalyticsOptions: TEST_ZANALYTICS_OPTIONS,
    zAnalyticsLopOptions: TEST_ZANALYTICS_LOP_OPTIONS,
    zhlUrls: {
        ...BASE_ZHL_URLS,
        salesforce: 'https://zillowhomeloans--test.sandbox.lightning.force.com/lightning',
    },
    zmAPIConfig: {
        autocompleteId: AUTOCOMPLETE_ID,
        partnerId: ZM_PARTNER_ID,
        serverURLs: {
            MAX: getMaxURL(),
            autocomplete: TEST_AUTOCOMPLETE,
            /**
             *    Auth is excluded from the services being relative to localhost to avoid
             *    issues that arose from the set-cookie header not functioning as intended
             *    on the auth.dev.zillowhomeloans.net domain. This needs to function for
             *    local auth to work effectively.
             */
            ...SERVICES.reduce(
                (prev, curr) =>
                    curr === 'auth'
                        ? prev
                        : {
                              ...prev,
                              [curr]: `https://${curr}.dev.zillowhomeloans.net/api`,
                          },
                {
                    auth: 'https://auth.dev.zillowhomeloans.net/api',
                }
            ),
        },
    },
    wpGraphQL:
        process.env.WP_GRAPHQL || 'https://cms-bedrock-dev.int.zgcp-consumer-nonprod-k8s.zg-int.net/zhl/graphql/',
    wpGraphQLKey: process.env.WP_APIKEY,
    splitApiKeyLoTools: process.env.SPLIT_API_LO_TOOLS_KEY ?? 'localhost',
    zgGraphEndpointURL: 'https://zg-graph-dev.int.zgcp-federated-nonprod-k8s.zg-int.net/graphql',
    cookieDomain: 'localhost',
};
