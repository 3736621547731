module.exports = {
    APP_NAME: 'zhl-lop',
    GOOGLE_ANALYTICS_LINK: 'https://www.google-analytics.com/analytics.js',
    GOOGLE_ANALYTICS_LINK_DEBUG: 'https://www.google-analytics.com/analytics.js',
    GOOGLE_SIGNIN_SCOPE:
        'https://www.googleapis.com/auth/plus.login https://www.googleapis.com/auth/plus.profile.emails.read',
    TEST_GTM_ID: 'GTM-M53V55W',
    GTM_ID: 'GTM-PPDFJW6',
    OPTIMIZE_CONTAINER_ID: 'OPT-PWZF3XS',
    APPLE_TOUCH_ICON: 'https://www.zillow.com/apple-touch-icon.png',
    // Assumes services are subdomains
    SERVICES: [
        'accounting-sync',
        'accounting-processor',
        'auth',
        'automated-underwriting',
        'bulk-update',
        'conditions',
        'credit',
        'document-generation',
        'homestory-proxy',
        'loan-fees',
        'leads',
        'loan-file',
        'pricing',
        'settings',
        'activate',
        'financials-verification',
    ],
    TEST_FB_APP_ID: 1951646468431310,
    TEST_GA_PROPERTY_ID: 'UA-55332635-5',
    TEST_GOOGLE_SIGNIN_CLIENTID: '238648973530-phn3l5mlqc61qms7m76qjta4mpubcedu.apps.googleusercontent.com',
    TEST_GOOGLE_SIGNIN_COOKIE_POLICY: 'single_host_origin',
    TEST_URL_OAUTH: 'https://oauthv2.qa.zillow.net',
    TEST_URL_MAX: 'https://mortgageapi.qa.zillow.net',
    TEST_AUTOCOMPLETE: 'https://static.qa.zillow.net',
    // Client/Service IDs
    ZM_PARTNER_ID: 'RD-XZQQPCN',
    AUTOCOMPLETE_ID: 'zhl',
    FB_APP_ID: 969739746392104,
    SENTRY_DSN: 'https://725c8991897a4b3c80b57e34abf39fbb@o168728.ingest.sentry.io/5679664',
    // Datadog RUM test settings
    TEST_SAMPLE_RATE: 0,
    TEST_REPLAY_SAMPLE_RATE: 0,
    CONFIG_STAGE: {
        LOCAL: 'local',
        DEV: 'dev',
        STAGING: 'staging',
        PROD: 'prod',
    },
    // Zanalytics settings
    TEST_ZANALYTICS_OPTIONS: {
        zillow: {
            apiKey: 'z_test',
            apiHost: 'analytics-zg-api.test.zillow.net',
            secure: true,
        },
    },
    PROD_ZANALYTICS_OPTIONS: {
        zillow: {
            apiKey: 'z_prod',
            apiHost: 'e.zg-api.com',
            secure: true,
        },
    },
    // Self service data platform settings - use for LOP
    TEST_ZANALYTICS_LOP_OPTIONS: {
        zhl: {
            apiKey: 'z_test',
            apiHost: 'analytics-zg-api.test.zillow.net',
            secure: true,
            writeKey: 'zhl_stage_web_itrc',
        },
    },
    PROD_ZANALYTICS_LOP_OPTIONS: {
        zhl: {
            apiKey: 'z_prod',
            apiHost: 'e.zg-api.com',
            secure: true,
            writeKey: 'zhl_prod_web_itrc',
        },
    },
    BASE_ZHL_URLS: {
        FANNIE_MAE: 'https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing',
        FHA_CONNECTION: 'https://entp.hud.gov/clas/index.cfm',
        FHA_HANDBOOK: 'https://www.hud.gov/program_offices/administration/hudclips/handbooks/hsgh',
        FHA_CONDO: 'https://entp.hud.gov/idapp/html/condlook.cfm',
        FREDDIE_MAC: 'https://guide.freddiemac.com',
        HIGHSPOT: 'https://zhl.highspot.com/spots/5d5ae98e811717101baef24f',
        VA_HANDBOOK: 'https://www.benefits.va.gov/WARMS/pam26_7.asp',
        VA_PORTAL: 'https://eauth.va.gov/accessva/?appId=lgyhub',
        VA_CONDO: 'https://lgy.va.gov/lgyhub/condo-report',
        MATRICES: 'https://zhl.highspot.com/items/6151f6344f221d92133225ce?lfrm=srp.4',
        ZILLOW_HOME: 'https://www.zillow.com',
    },
    BASE_DATADOG_LOP_CONFIG: {
        applicationId: '76cc22ae-e47b-4384-b477-4e0b0a4652cf',
        clientToken: 'pubaeb4bc0bc3c7a0bc6dc625bb7770c29f',
        env: process.env.CONFIG_STAGE,
        site: 'datadoghq.com',
        service: 'zhl-lop',
        version: process.env.VERSION,
    },
};
