const { CONFIG_STAGE } = require('./constants');

const DEFAULTS = {
    NODE_ENV: 'development',
    CONFIG_STAGE: CONFIG_STAGE.LOCAL,
};

// What denotes a required env property?
/**
 *  - Used in a config (__dirname/config/*). This is because the environment is imported in
 *    a node environment, but still relies on the webpack plugin on the "server" side, thus losing
 *    the environment variables on process.env
 *  - Used in client side code. An example: NODE_ENV
 *
 */
const REQUIRED_ENV_PROPS = [
    'NODE_ENV',
    'CONFIG_STAGE',
    'LOCAL_MAX',
    'CONFIG_CURRENT_DOMAIN',
    'MAX',
    'MAX_URL',
    'LOCAL_MAX_PORT',
    'WP_APIKEY',
    'WP_GRAPHQL',
    'SPLIT_API_LO_TOOLS_KEY',
    'VERSION',
];

module.exports = REQUIRED_ENV_PROPS.reduce(
    (config, prop) => ({
        ...config,
        [prop]: process.env[prop] ?? DEFAULTS[prop] ?? null, // https://webpack.js.org/plugins/environment-plugin/
    }),
    {}
);
