const { ThemeConstellation } = require('@zillow/constellation');
module.exports = imageURLs => {
    if (!Array.isArray(imageURLs)) {
        return '';
    }
    return `
        (() => {
            const IMAGES_ARRAY = ${JSON.stringify(imageURLs)};
            const BREAKPOINT_VALUES = ${JSON.stringify(Object.values(ThemeConstellation.constellation.breakpoints))};
            const getImageURLForBreakpoints = (urlArray) => {
                let url = null;
                const width = window.innerWidth;
                for (let i = 0; i < urlArray.length; i++) {
                    if (width < BREAKPOINT_VALUES[i + 1]) {
                        if (width === BREAKPOINT_VALUES[i]) {
                            // Update to the current urlArray index if exactly on the breakpoint
                            url = urlArray[i];
                        }
                        break;
                    }
                    url = urlArray[i] || url;
                }
                return url;
            };
            const HEAD = document.querySelector('head');
            const LINK_FRAGMENT = document.createDocumentFragment();
            IMAGES_ARRAY.forEach(urlArray => {
                const image = Array.isArray(urlArray) ? getImageURLForBreakpoints(urlArray) : null;
                if (image) {
                    const link = document.createElement('link');
                    link.setAttribute('href', image);
                    link.setAttribute('rel', 'preload');
                    link.setAttribute('as', 'image');
                    LINK_FRAGMENT.appendChild(link);
                }
            });
            HEAD.appendChild(LINK_FRAGMENT);
        })();
    `;
};
