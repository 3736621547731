const {
    BASE_ZHL_URLS,
    FB_APP_ID,
    GOOGLE_ANALYTICS_LINK,
    OPTIMIZE_CONTAINER_ID,
    GTM_ID,
    AUTOCOMPLETE_ID,
    SENTRY_DSN,
    ZM_PARTNER_ID,
    SERVICES,
    APPLE_TOUCH_ICON,
    PROD_ZANALYTICS_OPTIONS,
    BASE_DATADOG_LOP_CONFIG,
    PROD_ZANALYTICS_LOP_OPTIONS,
} = require('./constants');

module.exports = {
    currentDomain: 'https://www.zillowhomeloans.com',
    appleTouchIcon: APPLE_TOUCH_ICON,
    datadog: { replaySampleRate: 100, sampleRate: 10 },
    // TODO ZHLLP-4626 adjust these values once we have this in production for a week
    datadogLop: {
        ...BASE_DATADOG_LOP_CONFIG,
        defaultPrivacyLevel: 'mask',
        enableExperimentalFeatures: ['feature_flags'],
        sessionSampleRate: 0,
        sessionReplaySampleRate: 0,
        trackUserInteractions: false,
        trackResources: false,
        trackLongTasks: false,
    },
    facebookAppId: FB_APP_ID,
    gaLink: GOOGLE_ANALYTICS_LINK,
    optimizeContainerID: OPTIMIZE_CONTAINER_ID,
    optimizelyProjectID: '20120063403',
    gtmID: GTM_ID,
    gaPropertyId: 'UA-42026926-1',
    sentryDSN: SENTRY_DSN,
    zAnalyticsOptions: PROD_ZANALYTICS_OPTIONS,
    zAnalyticsLopOptions: PROD_ZANALYTICS_LOP_OPTIONS,
    zhlUrls: {
        ...BASE_ZHL_URLS,
        salesforce: 'https://zillowhomeloans.lightning.force.com/lightning',
    },
    zmAPIConfig: {
        autocompleteId: AUTOCOMPLETE_ID,
        partnerId: ZM_PARTNER_ID,
        serverURLs: {
            MAX: 'https://mortgageapi.zillow.com',
            autocomplete: 'https://zillowstatic.com',
            ...SERVICES.reduce(
                (prev, curr) => ({
                    ...prev,
                    [curr]: `https://${curr}.zillowhomeloans.com/api`,
                }),
                {}
            ),
        },
    },
    wpGraphQL: 'https://cms-bedrock.prod.kong.zg-int.net/zhl/graphql/',
    wpGraphQLKey: process.env.WP_APIKEY,
    splitApiKeyLoTools: process.env.SPLIT_API_LO_TOOLS_KEY,
    zgGraphEndpointURL: 'https://www.zillow.com/graphql',
    cookieDomain: '.zillowhomeloans.com',
};
