import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ivar, ivarSubset } from '@zillow/font-loading';
import Script from 'next/script';
import { CurrentPathContext } from '@zillow/zhl-wordpress-commons';
import getPreloadImageScript from '../src/common/getPreloadImageScript';
import initializeAnalytics from '../src/common/initializeAnalytics';

const ZHLApp = ({ Component, pageProps, router }) => {
    useEffect(() => {
        window.ZillowFontLoader.setIvarStyle(ivar);
        window.ZillowFontLoader.setIvarSubsetStyle(ivarSubset);
        window.ZillowFontLoader.init();

        initializeAnalytics();
    }, []);

    return (
        <CurrentPathContext.Provider value={router.asPath}>
            {pageProps.imagesToPreload?.length && (
                <Script
                    dangerouslySetInnerHTML={{
                        __html: getPreloadImageScript(pageProps.imagesToPreload),
                    }}
                />
            )}
            <Component {...pageProps} />
        </CurrentPathContext.Provider>
    );
};
ZHLApp.propTypes = {
    Component: PropTypes.elementType,
    pageProps: PropTypes.object,
    router: PropTypes.shape({
        asPath: PropTypes.string,
    }),
};
export default ZHLApp;
