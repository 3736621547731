const { TEST_URL_MAX } = require('./constants');
module.exports = {
    getMaxURL() {
        let maxURLOverride = TEST_URL_MAX;
        if (process.env.MAX) {
            maxURLOverride = `https://mortgageapi.${process.env.MAX}.zillow.net`;
        } else if (process.env.MAX_URL) {
            maxURLOverride = process.env.MAX_URL;
        } else if (process.env.LOCAL_MAX_PORT) {
            maxURLOverride = `http://localhost:${process.env.LOCAL_MAX_PORT}`;
        }
        return maxURLOverride;
    },
};
