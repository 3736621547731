const {
    BASE_ZHL_URLS,
    TEST_FB_APP_ID,
    OPTIMIZE_CONTAINER_ID,
    TEST_GTM_ID,
    TEST_GA_PROPERTY_ID,
    GOOGLE_ANALYTICS_LINK,
    SENTRY_DSN,
    TEST_URL_MAX,
    TEST_AUTOCOMPLETE,
    AUTOCOMPLETE_ID,
    ZM_PARTNER_ID,
    SERVICES,
    APPLE_TOUCH_ICON,
    TEST_SAMPLE_RATE,
    TEST_REPLAY_SAMPLE_RATE,
    TEST_ZANALYTICS_OPTIONS,
    BASE_DATADOG_LOP_CONFIG,
    TEST_ZANALYTICS_LOP_OPTIONS,
} = require('./constants');

module.exports = {
    currentDomain: 'https://www.staging.zillowhomeloans.net',
    appleTouchIcon: APPLE_TOUCH_ICON,
    datadog: { replaySampleRate: TEST_REPLAY_SAMPLE_RATE, sampleRate: TEST_SAMPLE_RATE },
    datadogLop: {
        ...BASE_DATADOG_LOP_CONFIG,
        defaultPrivacyLevel: 'mask',
        enableExperimentalFeatures: ['feature_flags'],
        sessionSampleRate: 50,
        sessionReplaySampleRate: 50,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
    },
    facebookAppId: TEST_FB_APP_ID,
    gaLink: GOOGLE_ANALYTICS_LINK,
    optimizeContainerID: OPTIMIZE_CONTAINER_ID,
    optimizelyProjectID: '20324151327',
    gtmID: TEST_GTM_ID,
    gaPropertyId: TEST_GA_PROPERTY_ID,
    sentryDSN: SENTRY_DSN,
    zAnalyticsOptions: TEST_ZANALYTICS_OPTIONS,
    zAnalyticsLopOptions: TEST_ZANALYTICS_LOP_OPTIONS,
    zhlUrls: {
        ...BASE_ZHL_URLS,
        salesforce: 'https://zillowhomeloans--preprod.lightning.force.com/lightning',
    },
    zmAPIConfig: {
        autocompleteId: AUTOCOMPLETE_ID,
        partnerId: ZM_PARTNER_ID,

        serverURLs: {
            MAX: TEST_URL_MAX,
            autocomplete: TEST_AUTOCOMPLETE,
            ...SERVICES.reduce(
                (prev, curr) => ({
                    ...prev,
                    [curr]: `https://${curr}.staging.zillowhomeloans.net/api`,
                }),
                {}
            ),
        },
    },
    wpGraphQL: 'https://cms-bedrock.stage.kong-nonprod.zg-int.net/zhl/graphql/',
    wpGraphQLKey: process.env.WP_APIKEY,
    splitApiKeyLoTools: process.env.SPLIT_API_LO_TOOLS_KEY,
    zgGraphEndpointURL: 'https://www.qa.zillow.net/graphql',
    cookieDomain: '.zillowhomeloans.net',
};
