const localConfig = require('../local');
const devConfig = require('../dev');
const stagingConfig = require('../staging');
const prodConfig = require('../prod');

function getAllKeys(obj) {
    if (typeof obj === 'object') {
        return Object.entries(obj).reduce((final, [key, value]) => {
            final[key] = getAllKeys(value);
            return final;
        }, {});
    }
    return null;
}

describe('configuration', () => {
    describe('configured environments', () => {
        it('has same keys defined across all environments', () => {
            const localDefinedKeys = getAllKeys(localConfig);
            [devConfig, stagingConfig, prodConfig].forEach(config => {
                const currentDefinedKeys = getAllKeys(config);
                expect(currentDefinedKeys).toEqual(localDefinedKeys);
            });
        });
    });
});
