import { initZanalytics, setdim, page } from '@zillow/universal-analytics';
import { zAnalyticsOptions } from '../../config/config';

const initializeAnalytics = () => {
    initZanalytics(window, document, zAnalyticsOptions);

    setdim({
        dimension68: window.location.href.replace(/(access_token|refresh_token)=[^&]+/g, ''),
        dimension69: document.referrer,
    });

    page(window.location.pathname);
};

export default initializeAnalytics;
